import React, { lazy, useEffect } from "react";
import { Security, LoginCallback, SecureRoute } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Route, useHistory, Switch, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setAuthorizationState,
  setOktaToken,
  setOktaUser,
} from "reducer/oktaSlice";

const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_ENV === 'PROD' ? process.env.REACT_APP_OKTA_PROD_ISSUER : process.env.REACT_APP_OKTA_NON_PROD_ISSUER,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID, // Browser / Authorize
  redirectUri: `${window.location.origin}/login/callback`,
  scopes: [
    "order_management:cust_mgmt.sto_order.order_id::create:",
    "ordermgmt.osdordersummary.read",
    "ordermgmt.osdordersummary.update",
    "ordermgmt.osdorder.read",
    "ordermgmt.stoorder.read",
    "openid",
    "profile",
    "email",
  ],
  // transformAuthState: async (oktaAuth, authState) => {
  //     if (!authState.isAuthenticated) {
  //         return authState;
  //     }
  //     const user = await oktaAuth.token.getUserInfo();
  //     authState.isAuthenticated = !!user;
  //     authState.users = user;
  //     return authState;
  // },
  tokenManager: {
    autoRenew: false,
    storage: "sessionStorage",
  },
  // cookies: {
  //     secure: true
  // },
  pkce: true,
});

export const getOktaAuth = () => {
  return oktaAuth.authStateManager._authState.idToken.claims.name;
};

export const getOktaAuthUsername = () => {
  return (oktaAuth.authStateManager && oktaAuth.authStateManager._authState) ? oktaAuth.authStateManager._authState.idToken.claims.name : "";
};

export const getOktaAuthPreferredUsername = () => {
  return (oktaAuth.authStateManager && oktaAuth.authStateManager._authState) ? oktaAuth.authStateManager._authState.idToken.claims.preferred_username : "";
};

export const getOktaAuthGroups = () => {
  return oktaAuth.authStateManager._authState.idToken.claims.groups;
};

export const getOktaAuthToken = () => {
  return oktaAuth.authStateManager._authState.accessToken.accessToken;
};
const Routes = () => {
  const dispatch = useDispatch();
  const OrdersTracker = lazy(() => import("components/operations/ordersTracker/OrdersTracker"));
  const OrdersSummary = lazy(() =>
    import("components/operations/ordersSummary/OrdersSummary")
  );
  const MetricsDashBoardLevel1 = lazy(() =>
    import("components/operations/ordersList/OrdersList")
  );
  const MetricsDashBoardLevel2 = lazy(() =>
    import("components/operations/orderDetails/OrderDetails")
  );

  useEffect(() => {
    dispatch(
      setAuthorizationState(
        oktaAuth &&
          oktaAuth.authStateManager &&
          oktaAuth.authStateManager.getAuthState()
      )
    );
    if (oktaAuth) {
      oktaAuth.getUser().then((user) => {
        dispatch(setOktaUser(user));
      });
    }
    dispatch(setOktaToken(oktaAuth && oktaAuth.getAccessToken()));
  });

  const history = useHistory();

  const restoreOriginalUri = (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };
  return (
    <>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Switch>
          <Route path="/login/callback" component={LoginCallback} />
          <SecureRoute
            key={`operations/orders`}
            path={[`/operations/orders/:type`,`/operations/orders/:type/:val`]}
            title="Metrics Dashboard Orders"
            component={MetricsDashBoardLevel1}
            exact
            nav={true}
            secure={true}
            breadcrumb="Metrcs Dashboard Level 1"
          />
          <SecureRoute
            key={`operations/items`}
            path={`/operations/items/:orderId`}
            title="Metrics Dashboard Level 2"
            component={MetricsDashBoardLevel2}
            exact
            nav={true}
            secure={true}
            breadcrumb="Metrcs Dashboard Level 2"
          />
          <SecureRoute
            key="operations"
            path={["/", "/operations"]}
            title="Metrics Dashboard"
            component={OrdersSummary}
            exact
            nav={true}
            secure={true}
            breadcrumb="Metrics Dashboard"
          />
          <SecureRoute
            key="track-orders"
            path={"/track-orders"}
            title="Track Orders"
            component={OrdersTracker}
            exact
            nav={true}
            secure={true}
            breadcrumb="Track Orders"
          />
          <Redirect from="/" to="/operations" />
        </Switch>
      </Security>
    </>
  );
};

export default Routes;