import thunk from 'redux-thunk';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist'

import appSlice from 'reducer/appSlice';
import OktaSlice from 'reducer/oktaSlice';
import dashboardSlice from 'reducer/dashboardSlice';
import reprocessOrdersApiSlice from 'reducer/reprocessOrdersApiSlice';

const reducers = combineReducers({
  appSliceReducer: appSlice,
  oktaSliceReducer: OktaSlice,
  dashboardSliceReducer : dashboardSlice,
  reprocessReducer : reprocessOrdersApiSlice,
});

const persistConfig = {
  key: 'root',
  storage,
}
const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.REACT_APP_ENV !== 'PROD',
  middleware: [thunk],
});

export const persistor = persistStore(store);
